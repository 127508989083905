import {
  Paper,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Template, TemplateList } from "../../../Models/testModels";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Clear } from "@mui/icons-material";
const FailedTestView = ({
  ongoingList,
  ViewDetailsPage,
  template_list,
  Clear,
  filterData,
  pdfData,
}: FailedTestViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const { errors } = formState;
  const history = useHistory();
  //   const [expanded, setExpanded] = useState<string | false>("panel1");
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  //   const columns: any[] = ["Asset No", "Location", "Instrument Type","Job End Date"];
  const columns: any[] = [
    "Unique Test ID",
    "Sl No",
    "Location",
    "Asset Type",
    "Test Object",
    "Job Start At",
    "Job End At",
    "Action",
  ];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<TemplateList[]>([]);
  const [searched, setSearched] = useState<string>("");
  const [FabSatA, SetFabSatA] = useState<any | undefined>();
  const [FabSatB, SetFabSatB] = useState<any | undefined>();

  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!ongoingList
      ? ongoingList.filter((row: TemplateList) => {
          return (
            row?.job_id?.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row?.sl_no?.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row?.tested_at?.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row?.instrument_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  useEffect(() => {
    if (!!ongoingList) {
      cancelSearch();
    }
  }, [ongoingList]);

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
    setValue("instrument_id", -1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const styles = {
    marginRight: "50px",
  };
  return (
    <Paper
      sx={{
        overflow: "hidden",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        margin: 2,
        boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      }}
    >
      <Box
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#035779",
          padding: 5,
        }}
      >
        <Typography style={{ textAlign: "center", color: "#fff" }}>
          Failed Tests
        </Typography>
      </Box>
      <Accordion
        disableGutters
        elevation={0}
        expanded={expanded === "panel1"}
        // expanded={expanded===false}
        onChange={handleChange1("panel1")}
        style={{ backgroundColor: "transparent", padding: 0, margin: 0 }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ height: 50 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              endIcon={<FilterListIcon />}
              sx={{ color: "#fff" }}
              onClick={() => handleChange1("panel1")}
            >
              Filter
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Start Date
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"start_date"}
                      defaultValue={""}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"datetime-local"}
                          style={{ width: "110%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      End Date
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"end_date"}
                      defaultValue={""}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"datetime-local"}
                          style={{ width: "110%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  marginTop={1}
                >
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Instrument Type
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"instrument_id"}
                      defaultValue={""}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          style={{ width: "109%", marginLeft: "2px " }}
                          id="outlined-select-currency"
                          select
                          size="small"
                          placeholder="Select Location"
                          defaultValue={"0"}
                          onBlur={onBlur}
                          value={value}
                          onChange={(data) => {
                            onChange(data.target.value);
                          }}
                        >
                          <MenuItem value={-1}>ALL</MenuItem>
                          {!!template_list &&
                            template_list.map((option: any) => (
                              <MenuItem
                                value={option.instrument_id}
                                key={option.instrument_id}
                              >
                                {option.instrument_name}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end ",
                //   marginRight: "2rem",
                marginRight: "0px",
                marginTop: "13px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#04afef",
                  margin: 1,
                }}
                onClick={() => (Clear(), reset())}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#2e7d32",
                  margin: 1,
                }}
                onClick={handleSubmit(filterData)}
              >
                Show
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#069A8E",
                  margin: 1,
                }}
                onClick={handleSubmit(pdfData)}
              >
                Generate PDF
              </Button>
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>
      <Grid container justifyContent={"center"} alignItems="center" margin={1}>
        <Grid item xs={8}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer sx={{ maxHeight: 480 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          //   style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell align="justify" key={column}>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!rows &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {row.job_id}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.sl_no}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        {row.tested_at}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {row.type_of_asset}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        {row.instrument_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        {row.job_start_at}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[6]}>
                        {row.job_end_at}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[7]}>
                        <Button
                          // onClick={() =>
                          //   history.push(`/tests/view-test`)
                          // }
                          onClick={() => ViewDetailsPage(row)}
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={ongoingList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default FailedTestView;
interface FailedTestViewProps {
  ongoingList: TemplateList[];
  ViewDetailsPage?: any;
  template_list: Template[];
  filterData?: any;
  pdfData?: any;
  Clear?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#04AFEF",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: { md: 17, sm: 12, xs: 8 },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
