import { Paper, Grid, Box, Typography } from "@mui/material";
import React from "react";
import { Test } from "../../../Models/testModels";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const ValidationResult = ({ Test, haserror }: ValidationResultProps) => {
  return (
    <Paper style={{ backgroundColor: "transparent" }}>
      {!!Test && (
        <Grid item>
          <Box
            style={{
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor:
                haserror == 1
                  ? "#d98282"
                  : haserror == 2
                  ? "#6fb070"
                  : "#78938a",
              padding: 8,
              width: 600,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                color: "#fff",
                fontWeight: "700",
              }}
            >
              Validation Result
            </Typography>
          </Box>
          <Grid
            container
            style={{ width: "100%" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={11}>
              <Grid container direction={"row"} padding={2}>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 550 }}>Rule</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{Test.validationRule}</Typography>
                </Grid>
              </Grid>
              <Grid container direction={"row"} padding={2}>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 550 }}>Status</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={{ justifyContent: "center" }}>
                    {Test.status}{" "}
                    {haserror == 1 ? (
                      <span style={{ color: "red", textAlign: "center" }}>
                        <CancelIcon style={{ fontSize: 16, marginTop: 5 }} />
                      </span>
                    ) : haserror == 2 ? (
                      <span style={{ color: "green", textAlign: "center" }}>
                        <CheckCircleIcon
                          style={{ fontSize: 16, marginTop: 5 }}
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default ValidationResult;
interface ValidationResultProps {
  Test?: Test;
  haserror?: number;
}
