import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

function Thersolds({
  theasoldval,
  getTitle,
  ChangeTher,
  test_status,
}: ThersoldsProps) {
  const [thld, Setthld] = useState<any[]>([]);
  const ChangeVal = (val: any, index: number) => {
    let temp = JSON.parse(JSON.stringify(theasoldval));
    temp[index].value = val;
    Setthld(temp);
  };
  useEffect(() => {
    if (!!theasoldval && theasoldval.length > 0) {
      //console.log(theasoldval);

      Setthld(theasoldval);
    }
  }, [theasoldval]);
  return (
    <Paper style={{ backgroundColor: "transparent" }}>
      {!!theasoldval && theasoldval.length > 0 && (
        <Grid item>
          <Box
            style={{
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "#78938a",
              padding: 8,
              width: 600,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                color: "#fff",
                fontWeight: "700",
              }}
            >
              Validation Parameter
            </Typography>
          </Box>
          <Grid container style={{ width: "100%" }} direction={"row"}>
            {!!thld &&
              thld.map((m: any, index: number) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  style={{ marginTop: 5, marginBottom: 3, paddingLeft: 5 }}
                >
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: 550 }}>
                        {getTitle(m.key)}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ marginLeft: 5, marginRight: 5 }}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>
                        <TextField
                          style={{ width: "80%", margin: "5px" }}
                          size="small"
                          type="number"
                          InputProps={{
                            readOnly:
                              !!test_status && test_status == 2 ? false : true,
                          }}
                          id="outlined-read-only-input"
                          key={index}
                          value={m.value}
                          onChange={(text: any) =>
                            ChangeVal(text.target.value, index)
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {!!test_status && test_status == 2 && (
                        <Button
                          onClick={() => ChangeTher(thld)}
                          variant="contained"
                          size="small"
                          style={{
                            color: "#ffff",
                            backgroundColor: "#035779",
                            margin: 5,
                          }}
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
          {!!test_status && test_status == 2 && (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              padding={2}
            ></Grid>
          )}
        </Grid>
      )}
    </Paper>
  );
}

export default Thersolds;
interface ThersoldsProps {
  theasoldval?: any[];
  getTitle?: any;
  ChangeTher?: any;
  test_status?: number;
}
