import React from "react";
import LoginMainView from "./LoginMainView";
import { connect } from "react-redux";
import { StoreState } from "../../../Models/reduxModels";
import { LoginAction } from "../../../Stores/actions/userActions";
import { useHistory } from "react-router-dom";
const LoginMain = ({ LoginAction }: LoginProps) => {
  const history = useHistory();
  const Submit = (props: any) => {
    props.device = "WEB";
    LoginAction({
      data: props,
      history: history,
    });
  };
  return <LoginMainView Submit={Submit} />;
};

const mapStateToProps = (state: StoreState) => {
  return {};
};

const mapDispatchToProps = {
  LoginAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginMain);

//
//LoginAction

interface LoginProps {
  LoginAction?: any;
}
