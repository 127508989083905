//Dev Url
export const baseUrl = "https://tm257ewp5h.execute-api.ap-south-1.amazonaws.com/v1/";
// export const baseUrl = "http://10.50.44.142:80/";
export const environment = {
  url: {
    loginUrl: "api/v3/login",
    ongoingTemplateListUrl: "api/v3/tests/listtests",
    getDomainUrl: "api/v3/commons/domains/type",
    GettingTemplateUrl: "api/v3/templates/getassets",
    userListUrl: "api/v3/users",
    ResetUserUrl: "api/v3/users/updateuser",
    file_downloadUrl: "api/v3/commons/file/download",
    validateTestUrl: "api/v3/rules/validate",
    saveTestUrl: "api/v3/tests/savetest",
    dashboardGetUrl: "api/v3/report/dashboard",
    templateGetUrl: "api/v3/templates/instruments",
    ChangePassowrdUrl: "api/v3/users/changepassword",
  },
};
