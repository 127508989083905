import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "../../../Models/reduxModels";
import { Template, TemplateList } from "../../../Models/testModels";
import { userDetails } from "../../../Models/userModels";
import { GetOngoingTest } from "../../../Stores/actions/testsActions";
import OngoingTestView from "./OngoingTestView";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";

const OngoingTests = ({
  ongoingList,
  GetOngoingTest,
  user,
  template_list,
}: OngoingTestsProps) => {
  useEffect(() => {
    GetOngoingTest({
      tested_by: null,
      test_status: 1,
      start_date: null,
      end_date: null,
      instrument_id: null,
    });
  }, []);
  const history = useHistory();
  const ViewOngoingDetailsPage = (data: any) => {
    history.push("/tests/view-test", {
      instrument_id: data.instrument_id,
      sl_no: data.sl_no,
      job_id: data.job_id,
    });
  };
  const filterData = (props: any) => {
    GetOngoingTest({
      tested_by: null,
      test_status: 1,
      start_date:
        !!props.start_date && props.start_date != ""
          ? moment(props.start_date)
              .format("MM/DD/YYYY HH:mm:ss a")
              .toUpperCase()
          : null,
      end_date:
        !!props.end_date && props.end_date != ""
          ? moment(props.end_date).format("MM/DD/YYYY HH:mm:ss a").toUpperCase()
          : null,
      instrument_id:
        !!props.instrument_id &&
        props.instrument_id != "" &&
        props.instrument_id != -1
          ? props.instrument_id
          : null,
    });
  };
  const pdfData = () => {
    // console.log("pdf", data);
    if (!!ongoingList) {
      var temp: any[] = [];
      for (let i = 0; i < ongoingList.length; i++) {
        temp[i] = [
          ongoingList[i].job_id ? ongoingList[i].job_id : "NA",
          ongoingList[i].sl_no ? ongoingList[i].sl_no : "NA",
          ongoingList[i].tested_at ? ongoingList[i].tested_at : "NA",
          ongoingList[i].type_of_asset ? ongoingList[i].type_of_asset : "NA",
          ongoingList[i].instrument_name
            ? ongoingList[i].instrument_name
            : "NA",
          ongoingList[i].job_start_at ? ongoingList[i].job_start_at : "NA",
        ];
      }

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var dd: any = {
        pageOrientation: "landscape",
        pageMargins: [20, 20, 20, 20],
        content: [
          !!ongoingList && ongoingList.length > 0
            ? {
                table: {
                  pageOrientation: "landscape",
                  widths: [300],
                  body: [
                    [
                      {
                        text: "Ongoing Test Object Details",
                        style: "header",
                        border: [false, false, false, false],
                      },
                    ],
                  ],
                },
              }
            : "",
          {
            table: {
              body: [[], []],
            },
          },
          !!ongoingList && ongoingList.length > 0
            ? {
                style: "tableExample",
                table: {
                  widths: ["*", "*", "*", "*", "*", "*"],
                  headerRows: 1,
                  pageOrientation: "landscape",
                  body: [
                    [
                      {
                        text: "Unique Test ID ",
                        style: "tabheader",
                        alignment: "center",
                      },
                      {
                        text: "Sl No ",
                        style: "tabheader",
                        alignment: "center",
                      },
                      {
                        text: "Location ",
                        style: "tabheader",
                        alignment: "center",
                      },
                      {
                        text: "Asset Type",
                        style: "tabheader",
                        alignment: "center",
                      },
                      {
                        text: "Test Object ",
                        style: "tabheader",
                        alignment: "center",
                      },
                      {
                        text: "Job Start At ",
                        style: "tabheader",
                        alignment: "center",
                      },
                    ],
                    ...temp,
                  ],
                },
                layout: {
                  fillColor: function (rowIndex: any) {
                    return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                  },
                  hLineWidth: function () {
                    return 0.5;
                  },
                  vLineWidth: function () {
                    return 0.5;
                  },
                },
              }
            : "N/A",
        ],
        styles: {
          header: {
            bold: true,
            fontSize: 16,
            color: "white",
            fillColor: "#035779",
          },
          subheader: {
            bold: true,
            fontSize: 12,
            color: "white",
            fillColor: "#4d90ab",
          },
          tabheader: {
            bold: true,
            fontSize: 12,
            color: "white",
            fillColor: "#3e86a3",
          },
          tabbody: {
            fontSize: 10,
          },
        },
        defaultStyle: {
          // alignment: 'justify'
        },
      };
      pdfMake.createPdf(dd).open();
    }
  };
  const Clear = () => {
    GetOngoingTest({
      tested_by: null,
      test_status: 1,
      start_date: null,
      end_date: null,
      instrument_id: null,
    });
  };
  return (
    <OngoingTestView
      ongoingList={ongoingList}
      ViewOngoingDetailsPage={ViewOngoingDetailsPage}
      template_list={template_list}
      filterData={filterData}
      Clear={Clear}
      pdfData={pdfData}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    ongoingList: state.tests.test_list,
    user: state.user.userDetails,
    template_list: state.tests.template_list,
  };
};
const mapDispatchToProps = {
  GetOngoingTest,
};

export default connect(mapStateToProps, mapDispatchToProps)(OngoingTests);
interface OngoingTestsProps {
  ongoingList: TemplateList[];
  user?: userDetails;
  GetOngoingTest?: any;
  template_list: Template[];
}
