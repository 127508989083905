import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "@emotion/react";
import CescLightTheme from "./themes/CescLightTheme";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SnackbarProvider } from "notistack";
import { Grid, Slide, Zoom } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Header from "./Views/Common/Header";
import Footer from "./Views/Common/Footer";
import SideMenu from "./Views/Common/SideMenu";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "./Views/Login";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Dashboard from "./Views/Dashboard";
import { StoreState } from "./Models/reduxModels";
import { connect } from "react-redux";
import { userDetails } from "./Models/userModels";
import ErrorToster from "./Views/ErrorToster";
import Loading from "./Views/Loading/Loading";
import Tests from "./Views/Tests";
import Profile from "./Views/Profile";
import UserManagement from "./Views/UserManagement";
function App({ userDetail }: Appinterface) {
  const [open, setOpen] = React.useState(true);
  return (
    <ThemeProvider theme={CescLightTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SnackbarProvider maxSnack={3}>
          <ErrorToster />
          <div className="app-container">
            <Grid container style={{ height: 70, backgroundColor: "#F0F2F5" }}>
              <Header />
            </Grid>
            <Grid
              container
              style={{
                height: "calc(100% - 105px)",
              }}
              direction={"row"}
            >
              <Loading />
              {!!userDetail && (
                <Grid
                  item
                  style={{
                    width: open ? 300 : 0,
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "calc(100% - 30px)",
                    transition: "all 0.2s",
                  }}
                >
                  <SideMenu setOpen={setOpen} open={open} />
                </Grid>
              )}
              <Grid
                item
                style={{
                  flexGrow: 1,
                  marginTop: 10,
                  marginBottom: 10,
                  overflowY: "scroll",
                  height: "calc(100% - 35px)",
                  width: open ? "calc(100% - 300px)" : "100%",
                }}
              >
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() =>
                      !!userDetail ? (
                        <Redirect to="/dashboard" />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  />
                  <Route path="/login" render={() => <Login />} />
                  <PrivateRoute path="/dashboard" component={Dashboard} />
                  <PrivateRoute path="/tests" component={Tests} />
                  <PrivateRoute path="/profile" component={Profile} />
                  <PrivateRoute
                    path="/user-management"
                    component={UserManagement}
                  />
                </Switch>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                height: 35,
                backgroundColor: "#F0F2F5",
                alignItems: "center",
                paddingLeft: 10,
              }}
            >
              <Footer />
            </Grid>
          </div>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    userDetail: state.user.userDetails,
  };
};
export default connect(mapStateToProps)(App);
interface Appinterface {
  userDetail?: userDetails;
}
