import { Grid, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FileDownloadSer } from "../../../Services/testService";
import "./detail.css";
import DownloadIcon from "@mui/icons-material/Download";
function ImageDownload({ file, test_id }: ImageDownloadProps) {
  const [base64, Setbase64] = useState<string | any>(undefined);
  const [file_type, Settype] = useState<boolean>(false);
  const DownloadImage = () => {
    if (!!file && file.length > 0) {
      let a = document.createElement("a"); //Create <a>
      a.href = base64; //Image Base64 Goes here
      a.download = file[0]; //File name Here
      a.click(); //Downloaded file
    }
  };
  useEffect(() => {
    const GetFile = async () => {
      if (!!file && file.length > 0) {
        FileDownloadSer({
          fileName: file[0],
          test_id: test_id,
        })
          .then((response) => {
            if (!!response.data) {
              Setbase64(response.data.file);
              if (file[0].match(/\.(jpg|jpeg|png|gif)$/i)) {
                Settype(true);
              } else {
                Settype(false);
              }
            }
          })
          .catch((error) => {});
      } else {
        Setbase64(undefined);
      }
    };
    GetFile();
  }, [file]);
  return !!base64 ? (
    <Grid>
      <Box
        style={{
          borderBottomRightRadius: 10,
          borderTopRightRadius: 10,
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "#78938a",
          padding: 8,
          width: 600,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            color: "#fff",
            fontWeight: "700",
          }}
        >
          View And Download Image
        </Typography>
      </Box>
      {file_type ? (
        <Grid container justifyContent="flex-start" alignItems="center">
          <img
            style={{ height: "200px", width: "400px" }}
            src={base64}
            alt="Image Uploaded"
          />

          <div className="wrapr">
            <DownloadIcon className="ico" onClick={() => DownloadImage()} />
          </div>
        </Grid>
      ) : (
        <Grid container justifyContent="flex-start" alignItems="center">
          <img
            style={{ height: "200px", width: "180px" }}
            src={require("../../../assets/doc.png")}
            alt="Image Uploaded"
          />
          <div className="wrapr2">
            <DownloadIcon className="ico2" onClick={() => DownloadImage()} />
          </div>
        </Grid>
      )}
    </Grid>
  ) : (
    <></>
  );
}

export default ImageDownload;

interface ImageDownloadProps {
  file?: string[];
  test_id: string;
}
