import {
  Paper,
  Grid,
  TextField,
  IconButton,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  tableCellClasses,
  styled,
  TableCell,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  CTDiagrameDetails,
  TemplateDetail,
  TemplateList,
  ValidationRes,
} from "../../../Models/testModels";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import FilterListIcon from "@mui/icons-material/FilterList";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { useHistory } from "react-router-dom";
import { keys } from "@mui/system";
import GenTestSection from "./GenTestSection";
import PdfGenerate from "./PdfGenerate";
import { useSnackbar } from "notistack";
import CheckIcon from "@mui/icons-material/Check";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import moment from "moment";
const DetailsTestView = ({
  instrument_type,
  ctList,
  template,
  getTitle,
  test,
  ValidateTest,
  StatusChange,
  changeData,
  validation,
}: DetailsTestViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
  } = useForm();
  const { errors } = formState;
  const columns: any[] = ["Test Name", "Rule", "Status", ""];
  const [open, onClose] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    setAllValue();
  }, [template]);

  const setAllValue = () => {
    if (!!template?.asset_no && template?.asset_no != "") {
      setValue("asset_no", template?.asset_no);
    }
    if (!!template?.job_sequence_id && template?.job_sequence_id != "") {
      setValue("job_sequence_id", template?.job_sequence_id);
    }
    if (!!template?.impedence && template?.impedence != "") {
      setValue("impedence", template?.impedence);
    }
    if (!!template?.type_of_asset && template?.type_of_asset != "") {
      setValue("type_of_asset", template?.type_of_asset);
    }
    if (!!template?.job_id && template?.job_id != "") {
      setValue("job_id", template?.job_id);
    }
    if (!!template?.job_start_at && template?.job_start_at != "") {
      setValue("job_start_at", template?.job_start_at);
    }
    if (!!template?.sl_no && template?.sl_no != "") {
      setValue("sl_no", template?.sl_no);
    }
    if (!!template?.make && template?.make != "") {
      setValue("make", template?.make);
    }
    if (!!template?.rating && template?.rating != "") {
      setValue("rating", template?.rating);
    }
    if (!!template?.kva && template?.kva != "") {
      setValue("kva", template?.kva);
    }
    if (!!template?.vector_group && template?.vector_group != "") {
      setValue("vector_group", template?.vector_group);
    }
    if (!!template?.ratio && template?.ratio != "") {
      setValue("ratio", template?.ratio);
    }
    if (!!template?.volts && template?.volts != "") {
      setValue("volts", template?.volts);
    }
    if (
      !!template?.type_of_cooling_mechanism &&
      template?.type_of_cooling_mechanism != ""
    ) {
      setValue(
        "type_of_cooling_mechanism",
        template?.type_of_cooling_mechanism
      );
    }
    if (!!template?.type_of_asset && template?.type_of_asset != "") {
      setValue("type_of_asset", template?.type_of_asset);
    }
    if (!!template?.purpose_of_test && template?.purpose_of_test != "") {
      setValue("purpose_of_test", template?.purpose_of_test);
    }
    if (!!template?.tested_at && template?.tested_at != "") {
      setValue("tested_at", template?.tested_at);
    }
    if (!!template?.job_end_at && template?.job_end_at != "") {
      setValue("job_end_at", template?.job_end_at);
    }
    if (!!template?.updated_at && template?.updated_at != "") {
      setValue("updated_at", template?.updated_at);
    }
    if (!!template?.location && template?.location != "") {
      setValue("location", template?.location);
    }
    if (!!template?.ref_no && template?.ref_no != "") {
      setValue("ref_no", template?.ref_no);
    }
    if (!!template?.impedence && template?.impedence != "") {
      setValue("impedence", template?.impedence);
    }
    if (!!template?.impedence_tap && template?.impedence_tap != "") {
      setValue("impedence_tap", template?.impedence_tap);
    }
    if (!!template?.impedence_tap && template?.impedence_tap != "") {
      setValue("impedence_tap", template?.impedence_tap);
    }
    if (!!template?.impedence_HV_IV && template?.impedence_HV_IV != "") {
      setValue("impedence_HV_IV", template?.impedence_HV_IV);
    }
    if (!!template?.impedence_HV_LV1 && template?.impedence_HV_LV1 != "") {
      setValue("impedence_HV_LV1", template?.impedence_HV_LV1);
    }
    if (!!template?.impedence_HV_LV2 && template?.impedence_HV_LV2 != "") {
      setValue("impedence_HV_LV2", template?.impedence_HV_LV2);
    }
    if (!!template?.impedence_LV1 && template?.impedence_LV1 != "") {
      setValue("impedence_LV1", template?.impedence_LV1);
    }
    if (!!template?.impedence_IV_LV1 && template?.impedence_IV_LV1 != "") {
      setValue("impedence_IV_LV1", template?.impedence_IV_LV1);
    }
    if (!!template?.impedence_IV_LV2 && template?.impedence_IV_LV2 != "") {
      setValue("impedence_IV_LV2", template?.impedence_IV_LV2);
    }
    if (!!template?.impedence_LV2 && template?.impedence_LV2 != "") {
      setValue("impedence_LV2", template?.impedence_LV2);
    }
    if (!!template?.no_of_phases && template?.no_of_phases != "") {
      setValue("no_of_phases", template?.no_of_phases);
    }
    if (!!template?.no_of_taps && template?.no_of_taps != "") {
      setValue("no_of_taps", template?.no_of_taps);
    }
    if (!!template?.nominal_tap_no && template?.nominal_tap_no != "") {
      setValue("nominal_tap_no", template?.nominal_tap_no);
    }
    if (
      !!template?.year_of_manufacturing &&
      template?.year_of_manufacturing != ""
    ) {
      setValue("year_of_manufacturing", template?.year_of_manufacturing);
    }
    if (!!template?.scheme_no && template?.scheme_no != "") {
      setValue("scheme_no", template?.scheme_no);
    }
    if (!!template?.rated_mva && template?.rated_mva != "") {
      setValue("rated_mva", template?.rated_mva);
    }
    if (!!template?.rating_mva_HV_IV && template?.rating_mva_HV_IV != "") {
      setValue("rating_mva_HV_IV", template?.rating_mva_HV_IV);
    }
    if (
      !!template?.rating_mva_HV_LV1_2 &&
      template?.rating_mva_HV_LV1_2 != ""
    ) {
      setValue("rating_mva_HV_LV1_2", template?.rating_mva_HV_LV1_2);
    }
    if (
      !!template?.rating_mva_IV_LV1_2 &&
      template?.rating_mva_IV_LV1_2 != ""
    ) {
      setValue("rating_mva_IV_LV1_2", template?.rating_mva_IV_LV1_2);
    }
    if (!!template?.rated_voltage_HV && template?.rated_voltage_HV != "") {
      setValue("rated_voltage_HV", template?.rated_voltage_HV);
    }
    if (!!template?.rated_voltage_IV && template?.rated_voltage_IV != "") {
      setValue("rated_voltage_IV", template?.rated_voltage_IV);
    }
    if (!!template?.rated_voltage_LV1 && template?.rated_voltage_LV1 != "") {
      setValue("rated_voltage_LV1", template?.rated_voltage_LV1);
    }
    if (!!template?.rated_voltage_LV2 && template?.rated_voltage_LV2 != "") {
      setValue("rated_voltage_LV2", template?.rated_voltage_LV2);
    }
    if (!!template?.rated_current_HV && template?.rated_current_HV != "") {
      setValue("rated_current_HV", template?.rated_current_HV);
    }
    if (!!template?.rated_current_IV && template?.rated_current_IV != "") {
      setValue("rated_current_IV", template?.rated_current_IV);
    }
    if (!!template?.rated_current_LV1 && template?.rated_current_LV1 != "") {
      setValue("rated_current_LV1", template?.rated_current_LV1);
    }
    if (
      !!template?.rated_current_IV_LV1 &&
      template?.rated_current_IV_LV1 != ""
    ) {
      setValue("rated_current_IV_LV1", template?.rated_current_IV_LV1);
    }
    if (!!template?.rated_current_LV2 && template?.rated_current_LV2 != "") {
      setValue("rated_current_LV2", template?.rated_current_LV2);
    }
    if (
      !!template?.new_under_contract_no &&
      template?.new_under_contract_no != ""
    ) {
      setValue("new_under_contract_no", template?.new_under_contract_no);
    }
    if (!!template?.repaired_by && template?.repaired_by != "") {
      setValue("repaired_by", template?.repaired_by);
    }
    if (!!template?.cesc_serial_no && template?.cesc_serial_no != "") {
      setValue("cesc_serial_no", template?.cesc_serial_no);
    }
    if (!!template?.booked_test_on && template?.booked_test_on != "") {
      setValue("booked_test_on", template?.booked_test_on);
    }
    if (!!template?.booked_test_at && template?.booked_test_at != "") {
      setValue("booked_test_at", template?.booked_test_at);
    }
    if (!!template?.job_received_from && template?.job_received_from != "") {
      setValue("job_received_from", template?.job_received_from);
    }
    if (!!template?.job_received_on && template?.job_received_on != "") {
      setValue("job_received_on", template?.job_received_on);
    }
    if (!!template?.reached_site_at && template?.reached_site_at != "") {
      setValue("reached_site_at", template?.reached_site_at);
    }
    if (!!template?.td_out_time && template?.td_out_time != "") {
      setValue("td_out_time", template?.td_out_time);
    }
    if (!!template?.test_object && template?.test_object != "") {
      setValue("test_object", template?.test_object);
    }
    if (!!template?.acceptance_test && template?.acceptance_test != "") {
      setValue("acceptance_test", template?.acceptance_test);
    }
    if (!!template?.ratio_fl1 && template?.ratio_fl1 != "") {
      setValue("ratio_fl1", template?.ratio_fl1);
    }
    if (!!template?.ratio_fl2 && template?.ratio_fl2 != "") {
      setValue("ratio_fl2", template?.ratio_fl2);
    }
    if (!!template?.dual_ratio_fl1 && template?.dual_ratio_fl1 != "") {
      setValue("dual_ratio_fl1", template?.dual_ratio_fl1);
    }
  };
  const OnmetaChange = (atta: any, index: number) => {
    let temp = JSON.parse(JSON.stringify(test));
    temp.metadata.test[index] = atta;
    changeData(temp);
  };
  const GeneratePDF = () => {
    onClose(true);
  };
  return (
    <Paper
      sx={{
        overflow: "hidden",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        margin: 2,
        boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      }}
    >
      <PdfGenerate
        open={open}
        onClose={onClose}
        metaDetail={test?.metadata?.test}
        getTitle={getTitle}
        template={template}
        instrument_type={instrument_type}
        validation={validation}
      />
      <Box
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#035779",
          padding: 5,
        }}
      >
        <Typography
          style={{ textAlign: "center", color: "#fff", fontWeight: "700" }}
        >
          Detailed Test Report{" "}
          {!!template ? "(" + template.instrument_name + ")" : ""}
        </Typography>
      </Box>
      <Accordion
        disableGutters
        elevation={0}
        defaultExpanded={false}
        style={{ backgroundColor: "transparent", padding: 0, margin: 0 }}
      >
        <AccordionSummary
          expandIcon={<FilterListIcon style={{ color: "#ffff" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: "rgb(27, 69, 82)",
            margin: 5,
            borderRadius: 4,
          }}
        >
          <Typography
            style={{ textAlign: "center", color: "#fff", fontWeight: "600" }}
          >
            Test Object Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <Grid
            style={{
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "#78938a",
              padding: 8,
              width: 600,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                color: "#fff",
                fontWeight: "700",
              }}
            >
              Job Detail
            </Typography>
          </Grid>
          <Grid container direction={"row"}>
            <Grid
              style={{
                backgroundColor: "#dee0e0",
                borderWidth: 0.5,
                borderColor: "#999999",
                borderStyle: "solid",
              }}
              item
              xs={4}
            >
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    Unique Test ID
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      padding: 5,
                    }}
                  >
                    {template?.job_id || "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                backgroundColor: "#dee0e0",
                borderWidth: 0.5,
                borderColor: "#999999",
                borderStyle: "solid",
              }}
              item
              xs={4}
            >
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    SL. No.
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      padding: 5,
                    }}
                  >
                    {template?.sl_no || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                backgroundColor: "#dee0e0",
                borderWidth: 0.5,
                borderColor: "#999999",
                borderStyle: "solid",
              }}
              item
              xs={4}
            >
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    Job Id
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      padding: 5,
                    }}
                  >
                    {template?.job_sequence_id || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                backgroundColor: "#c3e4f7",
                borderWidth: 0.5,
                borderColor: "#999999",
                borderStyle: "solid",
              }}
              item
              xs={4}
            >
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    Asset Type
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      padding: 5,
                    }}
                  >
                    {template?.type_of_asset || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                backgroundColor: "#c3e4f7",
                borderWidth: 0.5,
                borderColor: "#999999",
                borderStyle: "solid",
              }}
              item
              xs={4}
            >
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    Purpose of Test
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      padding: 5,
                    }}
                  >
                    {template?.purpose_of_test || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                backgroundColor: "#c3e4f7",
                borderWidth: 0.5,
                borderColor: "#999999",
                borderStyle: "solid",
              }}
              item
              xs={4}
            >
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    Acceptance Test
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      padding: 5,
                    }}
                  >
                    {template?.acceptance_test || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                backgroundColor: "#dee0e0",
                borderWidth: 0.5,
                borderColor: "#999999",
                borderStyle: "solid",
              }}
              item
              xs={4}
            >
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    Job Start At
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      padding: 5,
                    }}
                  >
                    {template?.job_start_at || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                backgroundColor: "#dee0e0",
                borderWidth: 0.5,
                borderColor: "#999999",
                borderStyle: "solid",
              }}
              item
              xs={4}
            >
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    Job End At
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      padding: 5,
                    }}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      padding: 5,
                    }}
                  >
                    {template?.job_end_at || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!!template &&
            !!template?.meta &&
            template.meta.map((item: any, index: number) => (
              <Grid key={index}>
                <Grid
                  style={{
                    borderBottomRightRadius: 10,
                    borderTopRightRadius: 10,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "#78938a",
                    padding: 8,
                    width: 600,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontWeight: "700",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Grid>
                <Grid container direction={"row"}>
                  {!!item &&
                    !!item.fields &&
                    item.fields.map((item: any, ind: number) => (
                      <Grid item xs={4} key={ind}>
                        <Grid
                          container
                          direction={"row"}
                          style={{
                            backgroundColor:
                              (ind + 1) % 6 > 0 && (ind + 1) % 6 <= 3
                                ? "#dee0e0"
                                : "#c3e4f7",
                            borderWidth: 0.5,
                            borderColor: "#999999",
                            borderStyle: "solid",
                          }}
                        >
                          <Grid item xs={5}>
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                padding: 5,
                              }}
                            >
                              {item?.place_holder}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                padding: 5,
                              }}
                            >
                              :
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                padding: 5,
                              }}
                            >
                              {!!template[item.key]
                                ? item.type == "DATE" ||
                                  item.type == "DATETIME" ||
                                  item.type == "TIME"
                                  ? moment(template[item.key]).format(
                                      item.type == "DATETIME"
                                        ? "MM/DD/YYYY HH:mm:ss a"
                                        : item.type == "DATE"
                                        ? "MM/DD/YYYY"
                                        : "HH:mm:ss a"
                                    )
                                  : template[item.key]
                                : "N/A"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            ))}
        </AccordionDetails>
        {(instrument_type == 2 ||
          instrument_type == 3 ||
          instrument_type == 4) &&
          !!ctList &&
          ctList.length > 0 && (
            <Box
              style={{
                borderBottomRightRadius: 10,
                borderTopRightRadius: 10,
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#78938a",
                padding: 8,
                width: 600,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "700",
                }}
              >
                CT Diagram Details
              </Typography>
            </Box>
          )}

        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          // columnSpacing={2}
          // marginX={2}
          marginY={3}
        >
          {(instrument_type == 2 ||
            instrument_type == 3 ||
            instrument_type == 4) &&
            !!ctList &&
            ctList.length > 0 &&
            ctList.map((m: CTDiagrameDetails, index: number) => (
              <Grid
                container
                // justifyContent="flex-start"
                // alignItems="center"
                // columnSpacing={2}
                // marginX={2}
                // marginY={2}
              >
                <Grid item xs={1.7}>
                  <Controller
                    control={control}
                    name={"ct_nomenclatures"}
                    // defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        style={{ width: "80%", margin: "5px" }}
                        // {...register("ct_nomenclatures", { required: true })}
                        // onBlur={onBlur}
                        size="small"
                        label="Nomenclature"
                        key={index}
                        id="outlined-read-only-input"
                        value={m.ct_nomenclature}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1.7}>
                  <Controller
                    control={control}
                    name={"ct_acc_class"}
                    // defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        style={{ width: "80%", margin: "5px" }}
                        // {...register("ct_acc_class", { required: true })}
                        onBlur={onBlur}
                        size="small"
                        id="outlined-read-only-input"
                        key={index}
                        label="ACC Class"
                        value={m.ct_acc_class}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1.7}>
                  <Controller
                    control={control}
                    name={"ct_burden"}
                    // defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        style={{ width: "80%", margin: "5px" }}
                        // {...register("ct_burden", { required: true })}
                        onBlur={onBlur}
                        size="small"
                        id="outlined-read-only-input"
                        label="Burden"
                        key={index}
                        value={m.ct_burden}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1.7}>
                  <Controller
                    control={control}
                    name={"ct_kneepoint_volt"}
                    // defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        style={{ width: "80%", margin: "5px" }}
                        // {...register("ct_kneepoint_volt", { required: true })}
                        onBlur={onBlur}
                        size="small"
                        id="outlined-read-only-input"
                        label="Kneepoint(V)"
                        key={index}
                        value={m.ct_kneepoint_volt}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1.7}>
                  <Controller
                    control={control}
                    name={"ct_imag"}
                    // defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        style={{ width: "80%", margin: "5px" }}
                        // {...register("ct_imag", { required: true })}
                        onBlur={onBlur}
                        size="small"
                        id="outlined-read-only-input"
                        label="Imag"
                        value={m.ct_imag}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1.7}>
                  <Controller
                    control={control}
                    name={"ct_rct"}
                    // defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        style={{ width: "80%", margin: "5px" }}
                        // {...register("ct_rct", { required: true })}
                        onBlur={onBlur}
                        size="small"
                        id="outlined-read-only-input"
                        label="Rct"
                        value={m.ct_rct}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1.7}>
                  <Controller
                    control={control}
                    name={"ct_purpose"}
                    // defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        style={{ width: "80%", margin: "5px" }}
                        // {...register("ct_purpose", { required: true })}
                        onBlur={onBlur}
                        size="small"
                        id="outlined-read-only-input"
                        label="Purpose"
                        key={index}
                        value={m.ct_purpose}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Accordion>

      {!!test &&
        test.metadata.test.map((item: any, index: number) => (
          <GenTestSection
            key={index}
            meta={item}
            template={test[item?.name]}
            test_id={test.test_id}
            getTitle={getTitle}
            test_status={test?.test_status}
            ChangeTher={(attr: any) => OnmetaChange(attr, index)}
            validation={validation}
          />
        ))}
      {/* {!!validation && (
        <Grid container>
          <Box
            style={{
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "#F0AD4E",
              padding: 8,
              width: 600,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                color: "#fff",
                fontWeight: "700",
              }}
            >
              Validation Result
            </Typography>
          </Box>
          {validation.tests.map((Test, index) => (
            <Paper
              key={index}
              sx={{
                overflow: "hidden",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                margin: 2,
                boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                width: "100%",
              }}
            >
              <Box
                style={{
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Test.passed ? "#6fb070" : "#d98282",
                  padding: 5,
                }}
              >
                <Typography style={{ textAlign: "center", color: "#fff" }}>
                  {getTitle(Test.name)}
                </Typography>
              </Box>
              <Grid
                container
                style={{ width: "100%" }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item xs={11}>
                  <Grid container direction={"row"} padding={2}>
                    <Grid item xs={3}>
                      <Typography style={{ fontWeight: 550 }}>Rule</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{ fontWeight: 550 }}>:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{Test.validationRule}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction={"row"} padding={2}>
                    <Grid item xs={3}>
                      <Typography style={{ fontWeight: 550 }}>
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{ fontWeight: 550 }}>:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography style={{ justifyContent: "center" }}>
                        {Test.status}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      )} */}
      {!!validation && (
        <>
          <Grid container>
            <Box
              style={{
                // borderBottomRightRadius: 10,
                // borderTopRightRadius: 10,
                borderRadius: 4,
                borderBottomRightRadius: 10,
                borderTopRightRadius: 10,
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#F0AD4E",
                padding: 8,
                // width: 600,
                // width: "100%",
                width: 600,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "700",
                }}
              >
                Overall Validation Status Validation Result
              </Typography>
            </Box>
            <Grid container direction={"row"}>
              <Grid item xs={4} padding={2}>
                <Card style={{ height: "100%" }}>
                  <CardMedia
                    component="img"
                    height="auto"
                    width={"100%"}
                    image={
                      validation.passed
                        ? require("../../../assets/passed.jpg")
                        : require("../../../assets/failed.png")
                    }
                    alt="Failed"
                  />

                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Overall Validation Status
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {validation.status}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <Grid container padding={2}>
                  {!!validation && (
                    <TableContainer sx={{ maxHeight: 480 }}>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        //   style={{ tableLayout: "fixed" }}
                      >
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <StyledTableCell align="justify" key={column}>
                                {column}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!!validation &&
                            validation.tests.map((row) => {
                              return (
                                <StyledTableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  // key={row.}
                                >
                                  <StyledTableCell
                                    align="left"
                                    key={columns[0]}
                                  >
                                    {getTitle(row.name)}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    key={columns[1]}
                                  >
                                    {row.validationRule}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    key={columns[2]}
                                  >
                                    {row.status}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    key={columns[3]}
                                  >
                                    {row.passed ? (
                                      <CheckIcon color={"success"} />
                                    ) : (
                                      <CloseIcon color="error" />
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        style={{ margin: 10 }}
      >
        <Grid item>
          <Button
            onClick={() => GeneratePDF()}
            variant="contained"
            style={{
              color: "#ffff",
              backgroundColor: "#069A8E",
              margin: 5,
            }}
          >
            Generate Pdf
          </Button>
          {(test?.test_status == 2 || test?.test_status == 4) &&
            (instrument_type == 1 || instrument_type == 5) && (
              <Button
                onClick={() => ValidateTest()}
                variant="contained"
                style={{
                  color: "#ffff",
                  backgroundColor: "#517b96",
                  margin: 5,
                }}
              >
                Validate Test
              </Button>
            )}
          {test?.test_status == 2 &&
            (((instrument_type == 1 || instrument_type == 5) &&
              !!validation?.passed) ||
              (instrument_type != 1 && instrument_type != 5)) && (
              <Button
                onClick={() => StatusChange(3)}
                variant="contained"
                style={{
                  color: "#ffff",
                  backgroundColor: "#52853d",
                  margin: 5,
                }}
              >
                PASSED
              </Button>
            )}
          {test?.test_status == 4 && (
            <Button
              onClick={() => StatusChange(1)}
              variant="contained"
              style={{
                color: "#ffff",
                backgroundColor: "#52853d",
                margin: 5,
              }}
            >
              Re-test
            </Button>
          )}
          {test?.test_status == 2 && (
            <Button
              onClick={() => StatusChange(4)}
              variant="contained"
              style={{ color: "#ffff", backgroundColor: "#fa4646", margin: 5 }}
            >
              FAILED
            </Button>
          )}
          {test?.test_status == 3 && (
            <Button
              onClick={() => StatusChange(5)}
              variant="contained"
              style={{ color: "#ffff", backgroundColor: "#035779", margin: 5 }}
            >
              COMPLETED
            </Button>
          )}
          <Tooltip
            placement="top-start"
            title={
              <>
                <Typography style={{ fontWeight: "600", fontSize: 14 }}>
                  1. If overall Validation Passed
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {" "}
                  i. Click on "Marked as Pass" Button to Pass the Test.
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {" "}
                  ii. Then click on "Completed" Button to Complete the Test.
                </Typography>
                <Typography style={{ fontWeight: "600", fontSize: 14 }}>
                  2. If overall Validation Failed
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {" "}
                  i. Click on "Marked as Fail" Button to Fail the Test or you
                  can update the threshold value to check whether it is passed
                  or failed.
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {" "}
                  ii. If clicked on "Marked as Fail" Button then you can send
                  the test again under Ongoing test section by clicking on
                  "Re-Test" button.
                </Typography>
              </>
            }
          >
            <IconButton color="info">
              <ContactSupportIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DetailsTestView;

interface DetailsTestViewProps {
  instrument_type: number;
  ctList?: CTDiagrameDetails[];
  template?: any;
  test?: any;
  getTitle?: any;
  ValidateTest?: any;
  StatusChange?: any;
  changeData?: any;
  validation?: ValidationRes;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#04AFEF",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: { md: 17, sm: 12, xs: 8 },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
