import { LoadingState } from "../../Models/loadingModelss";
import { ApiStatusActionTypes } from "../actions/apiStatusActions";
import { TestsActionTypes } from "../actions/testsActions";
import { UserActionTypes } from "../actions/userActions";
import InitialState from "./initialState";

const initialState: LoadingState = InitialState.loading;

export default function LoadingReducer(
  state: LoadingState = initialState,
  action: any
) {
  switch (action.type) {
    case ApiStatusActionTypes.Begin_Api_Call:
      return {
        ...state,
        count: state.count + action.payload.count,
        message: action.payload.message,
      };
    case ApiStatusActionTypes.Api_Call_Error:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case UserActionTypes.Login_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case TestsActionTypes.Get_Test_List_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case TestsActionTypes.Details_Test_Result_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case UserActionTypes.User_List_Get_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case UserActionTypes.User_Reset_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case TestsActionTypes.Save_Test_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case TestsActionTypes.Validation_Test_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case TestsActionTypes.Dashboard_Detail_Get_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case UserActionTypes.User_Password_Reset_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    default:
      return state;
  }
}
