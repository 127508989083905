import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../Models/reduxModels";
import { UserList } from "../../../Models/userModels";
import {
  UserListAction,
  UserResetAction,
} from "../../../Stores/actions/userActions";
import ResetDeviceView from "./ResetDeviceView";

const ResetDevices = ({
  UserListAction,
  userList,
  UserResetAction,
}: ResetDeviceProps) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    UserListAction();
  }, []);
  const ResetUser = (row: UserList) => {
    row.device = null;
    let payload = {
      data: row,
      enqueueSnackbar: enqueueSnackbar,
    };
    UserResetAction(payload);
  };
  return <ResetDeviceView ResetUser={ResetUser} userList={userList} />;
};

const mapStateToProps = (state: StoreState) => {
  return {
    userList: state.user.userList,
  };
};
const mapDispatchToProps = {
  UserListAction,
  UserResetAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetDevices);

interface ResetDeviceProps {
  UserListAction?: any;
  userList: UserList[];
  UserResetAction?: any;
}
