import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ProfileMain from "./ProfileMain";

const Profile = () => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}/profile-main`} />}
      />
      <Route
        exact
        path={`${path}/profile-main`}
        render={() => <ProfileMain />}
      />
    </Switch>
  );
};

export default Profile;
