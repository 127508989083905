import { passwordChangePayload, userDetails } from "../../Models/userModels";
import { LoginService, PasswordReset, UserLists, UserReset } from "../../Services/userService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";

export enum UserActionTypes {
  Logout_Success_Action = "[USER] Logout Success Action",
  Login_Success_Action = "[USER] Login Success Action",
  User_List_Get_Success_Action = "[USER] User List Get Success Action",
  User_Reset_Success_Action = "[USER] User Reset Success Action",
  User_Password_Reset_Success_Action= '[USER] User Password Reset Success Action'
}

export const UserLogoutSuccess = () => {
  return { type: UserActionTypes.Logout_Success_Action };
};

export const LoginAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Login in progress...",
      })
    );
    return LoginService(payload.data)
      .then((response) => {
        if (response.status == 200) {
          setTimeout(() => {
            payload.history.replace("/dashboard");
          }, 200);
          dispatch(LoginSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          ApiCallErrorAction({
            message: "Please Login again to continue.",
            errorCode: "",
          });
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              message: error?.response?.data?.message,
              errorCode: "",
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              message: "Error Encounter. Please Try Again!!",
              errorCode: "",
            })
          );
        }
      });
  };
};

export const LoginSuccess = (payload: userDetails) => {
  return { type: UserActionTypes.Login_Success_Action, payload: payload };
};

export const UserListAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "User List Loading...",
      })
    );
    return UserLists()
      .then((response) => {
        if (response.status == 200) {
          dispatch(UserListGetSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          ApiCallErrorAction({
            message: "Please Login again to continue.",
            errorCode: "",
          });
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              message: error?.response?.data?.message,
              errorCode: "",
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              message: "Error Encounter. Please Try Again!!",
              errorCode: "",
            })
          );
        }
      });
  };
};

export const UserListGetSuccess = (payload: userDetails) => {
  return {
    type: UserActionTypes.User_List_Get_Success_Action,
    payload: payload,
  };
};

export const UserResetAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Resetting User Device...",
      })
    );
    return UserReset(payload.data)
      .then(async (response) => {
        if (response.status == 200) {
          await payload.enqueueSnackbar("User Device Successfully Reset!", {
            variant: "success",
          });
          dispatch(UserResetSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          ApiCallErrorAction({
            message: "Please Login again to continue.",
            errorCode: "",
          });
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              message: error?.response?.data?.message,
              errorCode: "",
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              message: "Error Encounter. Please Try Again!!",
              errorCode: "",
            })
          );
        }
      });
  };
};

export const UserResetSuccessAction = () => {
  return {
    type: UserActionTypes.User_Reset_Success_Action,
  };
};


export const UserPasswordRestAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Password Resetting...",
      })
    );
    return PasswordReset(payload.data)
      .then(async (response) => {
        if (response.status == 200) {
          await payload.enqueueSnackbar("User Password Successfully Reset!", {
            variant: "success",
          });
          dispatch(UserPasswordResetSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          ApiCallErrorAction({
            message: "Please Login again to continue.",
            errorCode: "",
          });
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              message: error?.response?.data?.message,
              errorCode: "",
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              message: "Error Encounter. Please Try Again!!",
              errorCode: "",
            })
          );
        }
      });
  };
};

export const UserPasswordResetSuccessAction = () => {
  return {
    type: UserActionTypes.User_Password_Reset_Success_Action,
  };
};