import { baseUrl, environment } from "../environment";
import {
  FileDownload,
  GeneratingTemplatepayload,
  OngoingTestPayload,
  ValidateTestPayload,
} from "../Models/testModels";
import RestService from "./rest";

export const userServiceClient = new RestService({
  baseURL: baseUrl,
});

export const OngoingTestList = (data: OngoingTestPayload) => {
  return userServiceClient.post(environment.url.ongoingTemplateListUrl, data);
};

export const DoaminTempalteTitle = (data: string) => {
  return userServiceClient.get(environment.url.getDomainUrl + "/" + data);
};

export const GeneratingTemplates = (data: GeneratingTemplatepayload) => {
  return userServiceClient.post(environment.url.GettingTemplateUrl, data);
};

export const FileDownloadSer = (data: FileDownload) => {
  return userServiceClient.post(environment.url.file_downloadUrl, data);
};

export const ValidateTest = (data: ValidateTestPayload) => {
  return userServiceClient.post(environment.url.validateTestUrl, data);
};

export const SaveTestData = (data: any) => {
  return userServiceClient.post(environment.url.saveTestUrl, data);
};
export const DashboardDetailGet = (data: number) => {
  return userServiceClient.post(environment.url.dashboardGetUrl, {
    userid: null,
  });
};
export const GetTemplateService = () => {
  return userServiceClient.get(environment.url.templateGetUrl);
};
