import { UserMain } from "../../Models/userModels";
import { UserActionTypes } from "../actions/userActions";
import InitialState from "./initialState";

const initialState: UserMain = InitialState.user;

export default function UserReducers(
  state: UserMain = initialState,
  action: any
) {
  switch (action.type) {
    case UserActionTypes.Logout_Success_Action:
      return { ...initialState };
    case UserActionTypes.Login_Success_Action:
      return { ...state, userDetails: action.payload };
    case UserActionTypes.User_List_Get_Success_Action:
      return { ...state, userList: action.payload };
    case UserActionTypes.User_Password_Reset_Success_Action:
      return {...initialState}
    default:
      return state;
  }
}
