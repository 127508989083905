import { baseUrl, environment } from "../environment";
import { LoginPayload, passwordChangePayload, UserList } from "../Models/userModels";
import RestService from "./rest";

export const userServiceClient = new RestService({
  baseURL: baseUrl,
});

export const LoginService = (data: LoginPayload) => {
  return userServiceClient.post(environment.url.loginUrl, data);
};

export const UserLists = () => {
  return userServiceClient.get(environment.url.userListUrl);
};
export const UserReset = (data: UserList) => {
  return userServiceClient.post(environment.url.ResetUserUrl, {
    device: null,
    userId: data._id,
  });
};

export const PasswordReset=(data: passwordChangePayload) => {
  return userServiceClient.post(environment.url.ChangePassowrdUrl, data)
}