import { TestsMain } from "../../Models/testModels";
import { TestsActionTypes } from "../actions/testsActions";
import InitialState from "./initialState";

const initialState: TestsMain = InitialState.tests;

export default function TestsReducers(
  state: TestsMain = initialState,
  action: any
) {
  switch (action.type) {
    case TestsActionTypes.Get_Test_List_Success_Action:
      return {
        ...state,
        test_list: action.payload.testList,
        template: undefined,
        template_list: action.payload.instrument_type, 
      };
    case TestsActionTypes.Details_Test_Result_Success_Action:
      return {
        ...state,
        template: action.payload.template,
        domain_template: action.payload.domain,
        validation_res: undefined,
      };
    case TestsActionTypes.Save_Test_Success_Action:
      return { ...state, template: action.payload, validation_res: undefined };
    case TestsActionTypes.Validation_Test_Success_Action:
      return { ...state, validation_res: action.payload };
    case TestsActionTypes.Dashboard_Detail_Get_Success_Action:
      return {...state, dashboard_detail: action.payload};
    default:
      return state;
  }
}
