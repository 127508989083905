import { Grid, Typography } from "@mui/material";
import React from "react";

function Header() {
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid item xs={3} style={{ paddingLeft: 10 }}>
        <img
          style={{ height: 70 }}
          src={require("../../../assets/RPSG-LOGO-FINAL.png")}
        />
      </Grid>
      <Grid item xs={6} style={{ textAlign: "center" }}>
        <Typography
          style={{ fontSize: 22, fontWeight: "bold", color: "#914197" }}
        >
          INSTRUMENT TESTING MANAGEMENT
        </Typography>
      </Grid>
      <Grid item xs={3} style={{ textAlign: "end" }}>
        <img
          style={{ height: 70 }}
          src={require("../../../assets/CESC-LOGO-FINAL.png")}
        />
      </Grid>
    </Grid>
  );
}

export default Header;
