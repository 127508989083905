import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "../../../Models/reduxModels";
import { DashboardDetail, SavedTest } from "../../../Models/testModels";
import { userDetails } from "../../../Models/userModels";
import { GetDashboardDetail } from "../../../Stores/actions/testsActions";
import DashBoardMainView from "./DashBoardMainView";

const DashboardMain = ({
  GetDashboardDetail,
  dashboarddetail,
  navigation,
  user,

}: DashboardMainProps) => {
  useEffect(() => {
    if (!!user) {
      GetDashboardDetail(user.userid);
    }
  }, []);
  console.log("dashboard index", dashboarddetail);
  const history = useHistory();
  const OpenOngoingTest =(data: any)=>{
    console.log("data ongoing");
    history.push("/tests/view-test", {
      instrument_id: data.instrument_id,
      sl_no: data.sl_no,
      job_id: data.job_id,
    });
  }
  const OpenSubmittedTest =(data: any)=>{
    console.log("data submitted");
    history.push("/tests/view-test", {
      instrument_id: data.instrument_id,
      sl_no: data.sl_no,
      job_id: data.job_id,
    });
  }
  const OpenOngoingTestList =()=>{
    history.push("/tests/ongoing-tests")
  }
  const OpenSubmittedTestList =()=>{
    history.push("/tests/submitted-tests")
  }
  return <DashBoardMainView dashboarddetail={dashboarddetail} OpenOngoingTest={OpenOngoingTest} OpenSubmittedTest={OpenSubmittedTest} OpenOngoingTestList={OpenOngoingTestList} OpenSubmittedTestList={OpenSubmittedTestList}/>;
};

const mapStateToProps = (state: StoreState) => {
  return {
    dashboarddetail: state.tests.dashboard_detail,
    // user: null,
    user: state.user.userDetails,
  };
};

const mapDispatchToProps = {
  GetDashboardDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMain);

interface DashboardMainProps {
  navigation?: any;
  dashboarddetail?: DashboardDetail;
  GetDashboardDetail?: any;
  user?: userDetails;

}
