import { Accordion, AccordionSummary, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Addlfield from "./Addlfield";
import SubTestSection from "./SubTestSection";
import FilterListIcon from "@mui/icons-material/FilterList";
import ImageDownload from "./ImageDownload";
import Thersold from "./Thersolds";
import { ValidationRes } from "../../../Models/testModels";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ValidationResult from "./ValidationResult";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
function GenTestSection({
  meta,
  template,
  getTitle,
  test_id,
  ChangeTher,
  test_status,
  validation,
  templateType,
}: GenTestSectionProps) {
  const [haserror, Seterror] = useState<number>(0);
  useEffect(() => {
    if (
      !!validation &&
      validation.tests &&
      validation.tests.find((m) => m.name == meta.name && m.passed == false)
    ) {
      Seterror(1);
    } else if (
      !!validation &&
      validation.tests &&
      validation.tests.find((m) => m.name == meta.name && m.passed == true)
    ) {
      Seterror(2);
    } else {
      Seterror(0);
    }
  }, [validation]);
  return (
    <Grid>
      <Accordion
        disableGutters
        elevation={0}
        defaultExpanded={false}
        style={{ backgroundColor: "#ffff", padding: 0, marginTop: 20 }}
      >
        <AccordionSummary
          expandIcon={<FilterListIcon style={{ color: "#ffff" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor:
              haserror == 1
                ? "rgba(217, 130, 130,0.7)"
                : haserror == 2
                ? "rgba(111, 176, 112, 0.8)"
                : "rgb(27, 69, 82)",
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 4,
            alignItems: "center",
          }}
        >
          <Grid container direction={"row"} style={{ width: "100%" }}>
            <Grid xs={9} item>
              <Typography
                style={{
                  textAlign: "left",
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                {getTitle(meta.name)}
              </Typography>
            </Grid>
            <Grid xs={3} item>
              <Typography
                style={{
                  textAlign: "start",
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                {haserror == 1 ? (
                  <span style={{ color: "red", textAlign: "center" }}>
                    [ <CancelIcon style={{ fontSize: 16, marginTop: 5 }} />{" "}
                    Validation Failed]
                  </span>
                ) : haserror == 2 ? (
                  <span style={{ color: "green", textAlign: "center" }}>
                    [ <CheckCircleIcon style={{ fontSize: 16, marginTop: 5 }} />{" "}
                    Validation Pass]
                  </span>
                ) : !!test_status &&
                  test_status > 1 &&
                  (templateType == 1 || templateType == 5) ? (
                  <span style={{ color: "#ffff", textAlign: "center" }}>
                    [ <CrisisAlertIcon style={{ fontSize: 16, marginTop: 5 }} />{" "}
                    Not Executed]
                  </span>
                ) : (
                  <></>
                )}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>

        <Addlfield
          meta_name={meta.name}
          aditional_fields={meta.aditional_fields}
          template={template}
          getTitle={getTitle}
        />

        {!!meta?.subtest &&
          meta?.subtest.map((m: any, index: number) => (
            <SubTestSection
              template={template[m.name]}
              meta={m}
              key={index}
              getTitle={getTitle}
            />
          ))}
        {!!meta?.aditional_fields &&
          meta?.aditional_fields.map((m: any, index: number) =>
            m.type == "IMAGE" ? (
              <ImageDownload
                key={index}
                test_id={test_id}
                file={template[m.name]}
              />
            ) : (
              <Grid key={index}></Grid>
            )
          )}
        <Thersold
          test_status={test_status}
          getTitle={getTitle}
          theasoldval={meta?.thresoholds}
          ChangeTher={(attr: any) => ChangeTher({ ...meta, thresoholds: attr })}
        />
        <ValidationResult
          Test={
            !!validation
              ? validation.tests.find((m) => m.name == meta.name)
              : undefined
          }
          haserror={haserror}
        />
      </Accordion>
    </Grid>
  );
}

export default GenTestSection;

interface GenTestSectionProps {
  meta?: any;
  template?: any;
  getTitle?: any;
  test_id: string;
  ChangeTher?: any;
  test_status?: number;
  validation?: ValidationRes;
  templateType?: number;
}
