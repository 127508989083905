import { combineReducers } from "redux";
import ErrorReducer from "./errorReducer";
import LoadingReducer from "./loadingReducer";
import TestsReducers from "./testReducer";
import UserReducers from "./userReducer";
const rootReducer = combineReducers({
  loading: LoadingReducer,
  error: ErrorReducer,
  user: UserReducers,
  tests: TestsReducers,
});

export default rootReducer;
