import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AllTests from "./AllTests";
import CompletedTests from "./CompletedTests";
import FailedTests from "./FailedTests";
import OngoingTests from "./OngoingTests";
import PassedTests from "./PassedTests";
import SubmittedTest from "./SubmittedTests";
import DetailsTest from "./TestDetails";

const Tests = () => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}/ongoing-tests`} />}
      />
      <Route
        exact
        path={`${path}/ongoing-tests`}
        render={() => <OngoingTests />}
      />
      <Route
        exact
        path={`${path}/submitted-tests`}
        render={() => <SubmittedTest />}
      />
      <Route
        exact
        path={`${path}/completed-tests`}
        render={() => <CompletedTests />}
      />
      <Route
        exact
        path={`${path}/passed-tests`}
        render={() => <PassedTests />}
      />
      <Route
        exact
        path={`${path}/failed-tests`}
        render={() => <FailedTests />}
      />
      <Route exact path={`${path}/all-tests`} render={() => <AllTests />} />
      <Route exact path={`${path}/view-test`} render={() => <DetailsTest />} />
    </Switch>
  );
};

export default Tests;
