import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Fade,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  styled,
  Typography,
  withStyles,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import "./SideNav.css";
import MuiList from "@mui/material/List";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import QueueIcon from "@mui/icons-material/Queue";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import TaskIcon from "@mui/icons-material/Task";
import CollectionsBookmarkRoundedIcon from '@mui/icons-material/CollectionsBookmarkRounded';
import LockResetIcon from "@mui/icons-material/LockReset";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { useHistory, useLocation } from "react-router-dom";
import { StoreState } from "../../../Models/reduxModels";
import { UserLogoutSuccess } from "../../../Stores/actions/userActions";
import { connect } from "react-redux";
import { userDetails } from "../../../Models/userModels";
import AddTaskIcon from "@mui/icons-material/AddTask";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
function SideMenu({ UserLogoutSuccess, User, open, setOpen }: SideMenuProps) {
  const [path, SetPath] = useState<string>("dashboard-main");
  const history = useHistory();
  const location = useLocation();

  const containerRef = React.useRef(null);
  const handleClick = () => {
    setOpen(!open);
  };

  const List = styled(MuiList)({
    "&& .Mui-selected, && .Mui-selected:hover": {
      backgroundColor: "red",
      "&, & .MuiListItemIcon-root": {
        color: "pink",
      },
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: "#58585B",
      "&, & .MuiListItemIcon-root": {
        color: "#58585B",
      },
    },
    "& .MuiAccordion-root:before": {
      height: 0,
    },
    "& .MuiAccordionSummary-root": {
      height: 48,
    },
    "& .MuiAccordionSummary-root:hover": {
      backgroundColor: "#58585B",
    },
  });
  useEffect(() => {
    SetPath("/" + location.pathname.split("/")[2]);
  }, [location.pathname]);
  const LogOut = () => {
    UserLogoutSuccess();
  };

  return (
    <>
      <Slide
        direction="right"
        in={open}
        container={containerRef.current}
        mountOnEnter
        unmountOnExit
        style={{ width: 280 }}
      >
        <Grid
          container
          className="backgound"
          style={{
            minHeight: "calc(100% - 20px)",
            width: "calc(100% - 20px)",
            marginTop: 10,
            marginBottom: 10,
            borderRadius: 10,
            marginLeft: 10,
            boxShadow: "-1px 1px 20px 5px rgba(0,0,0,0.32)",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <List style={{ width: "100%", justifyContent: "center" }}>
            <Grid container justifyContent={"flex-end"}>
              <Grid item style={{ marginRight: -24 }}>
                <IconButton
                  onClick={handleClick}
                  style={{ height: 15, width: 15 }}
                >
                  <ArrowLeftIcon style={{ fontSize: 60, color: "#ffff" }} />
                </IconButton>
              </Grid>
            </Grid>

            <Accordion
              elevation={0}
              style={{
                backgroundColor: "transparent",
                padding: 0,
                margin: 0,
                marginBottom: 10,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#ffff" }} />}
                style={{
                  padding: 0,
                  margin: 0,
                  borderRadius: 10,
                }}
              >
                <Grid container direction={"row"} alignItems={"center"}>
                  <Grid item>
                    <AccountCircleIcon
                      style={{ fontSize: 40, color: "#ffff", paddingRight: 5 }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: "#fff" }}>
                      {User?.fullName}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={(event) => history.push(`/profile/profile-main`)}
                    className="list-item"
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                      backgroundColor: path == "/profile-main" ? "#616161" : "",
                    }}
                  >
                    <ListItemIcon>
                      <AdminPanelSettingsIcon style={{ color: "#43B0F1" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="My Profile"
                      style={{ color: "#ffff" }}
                      primaryTypographyProps={{ fontSize: 14 }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => LogOut()}
                    className="list-item"
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon style={{ color: "#FF595E" }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 14 }}
                      primary="Logout"
                      style={{ color: "#ffff" }}
                    />
                  </ListItemButton>
                </ListItem>
              </AccordionDetails>
            </Accordion>
            <Divider color="#6A6A6F" />
            <ListItemButton
              className="list-item"
              style={{
                borderRadius: 10,
                marginBottom: 10,
                marginTop: 10,
                backgroundColor: path == "/dashboard-main" ? "#616161" : "",
              }}
              onClick={() => history.push(`/dashboard/dashboard-main`)}
            >
              <ListItemIcon>
                <DashboardIcon style={{ color: "#2ECC71" }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" style={{ color: "#ffff" }} />
            </ListItemButton>
            <Accordion
              elevation={0}
              style={{
                backgroundColor: "transparent",
                borderRadius: 10,
                marginBottom: 10,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#ffff" }} />}
                className="list-item"
                style={{
                  borderRadius: 10,
                }}
              >
                <ListItemIcon style={{ alignItems: "center" }}>
                  <AssignmentIcon style={{ color: "#FBF2C4" }} />
                </ListItemIcon>
                <ListItemText primary="Tests" style={{ color: "#ffff" }} />
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <List disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    className="list-item"
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                      marginTop: 10,
                      backgroundColor:
                        path == "/ongoing-tests" ? "#616161" : "",
                    }}
                    onClick={() => history.push(`/tests/ongoing-tests`)}
                  >
                    <ListItemIcon>
                      <QueueIcon style={{ color: "#43B0F1" }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 14 }}
                      primary="Ongoing Tests"
                      style={{ color: "#ffff" }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    className="list-item"
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                      marginTop: 10,
                      backgroundColor:
                        path == "/submitted-tests" ? "#616161" : "",
                    }}
                    onClick={() => history.push(`/tests/submitted-tests`)}
                  >
                    <ListItemIcon>
                      <TouchAppIcon style={{ color: "#FEC260" }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 14 }}
                      primary="Submitted Tests"
                      style={{ color: "#ffff" }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    className="list-item"
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                      marginTop: 10,
                      backgroundColor: path == "/passed-tests" ? "#616161" : "",
                    }}
                    onClick={() => history.push(`/tests/passed-tests`)}
                  >
                    <ListItemIcon>
                      <AddTaskIcon style={{ color: "#3FA796" }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 14 }}
                      primary="Passed Tests"
                      style={{ color: "#ffff" }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => history.push(`/tests/completed-tests`)}
                    sx={{ pl: 4 }}
                    className="list-item"
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                      marginTop: 10,
                      backgroundColor:
                        path == "/completed-tests" ? "#616161" : "",
                    }}
                  >
                    <ListItemIcon>
                      <TaskIcon style={{ color: "#007F4E" }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 14 }}
                      primary="Completed Tests"
                      style={{ color: "#ffff" }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    className="list-item"
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                      marginTop: 10,
                      backgroundColor: path == "/failed-tests" ? "#616161" : "",
                    }}
                    onClick={() => history.push(`/tests/failed-tests`)}
                  >
                    <ListItemIcon>
                      <SmsFailedIcon style={{ color: "#bd0b0b" }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 14 }}
                      primary="Failed Tests"
                      style={{ color: "#ffff" }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    className="list-item"
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                      marginTop: 10,
                      backgroundColor: path == "/all-tests" ? "#616161" : "",
                    }}
                    onClick={() => history.push(`/tests/all-tests`)}
                  >
                    <ListItemIcon>
                      <CollectionsBookmarkRoundedIcon style={{ color: "00ABB3" }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 14 }}
                      primary="All Tests"
                      style={{ color: "#ffff" }}
                    />
                  </ListItemButton>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={0}
              style={{
                backgroundColor: "transparent",
                borderRadius: 10,
                marginBottom: 10,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#ffff" }} />}
                className="list-item"
                style={{
                  borderRadius: 10,
                }}
              >
                <ListItemIcon style={{ alignItems: "center" }}>
                  <ManageAccountsIcon style={{ color: "#D7E3FC" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Manage User"
                  style={{ color: "#ffff" }}
                />
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <List disablePadding>
                  <ListItemButton
                    onClick={() =>
                      history.push(`/user-management/reset-devices`)
                    }
                    sx={{ pl: 4 }}
                    className="list-item"
                    style={{
                      borderRadius: 10,
                      marginTop: 10,
                      backgroundColor:
                        path == "/reset-devices" ? "#616161" : "",
                    }}
                  >
                    <ListItemIcon>
                      <LockResetIcon style={{ color: "#FFC920" }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 14 }}
                      primary="Reset Device"
                      style={{ color: "#ffff" }}
                    />
                  </ListItemButton>
                </List>
              </AccordionDetails>
            </Accordion>
          </List>
        </Grid>
      </Slide>
      <Slide
        direction="right"
        in={!open}
        container={containerRef.current}
        mountOnEnter
        unmountOnExit
        timeout={800}
        style={{
          position: "absolute",
          left: 0,
          top: "50%",
        }}
      >
        <Grid>
          <IconButton
            onClick={handleClick}
            style={{
              height: 15,
              width: 15,
            }}
          >
            <ArrowRightIcon style={{ fontSize: 100, color: "#049ADD" }} />
          </IconButton>
        </Grid>
      </Slide>
    </>
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    User: state.user.userDetails,
  };
};

const mapDispatchToProps = {
  UserLogoutSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

interface SideMenuProps {
  UserLogoutSuccess?: any;
  User?: userDetails;
  open?: boolean;
  setOpen?: any;
}
