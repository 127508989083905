import {
  DashboardDetailGet,
  DoaminTempalteTitle,
  GeneratingTemplates,
  GetTemplateService,
  OngoingTestList,
  SaveTestData,
  ValidateTest,
} from "../../Services/testService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { UserLogoutSuccess } from "./userActions";

export enum TestsActionTypes {
  Get_Test_List_Success_Action = "[TESTS] Get Test List Success Action",
  Details_Test_Result_Success_Action = "[TESTS] Details Test Result Success Action",
  Save_Test_Success_Action = "[TESTS] Save Test Success Action",
  Validation_Test_Success_Action = "[TESTS] Validation Test Success Action",
  Dashboard_Detail_Get_Success_Action = "[TESTS] Dashboard Detail Get SuccessAction",
}

export const GetOngoingTest = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Ongoing Test Loading...",
      })
    );
    return Promise.all([GetTemplateService(),OngoingTestList(payload)]) 
      .then((response) => {
        if (response[0].status == 200 && response[1].status == 200 ) {
          dispatch(GetOngoinTestSuccessAction({testList: response[1].data, instrument_type: response[0].data}));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          ApiCallErrorAction({
            message: "Please Login again to continue.",
            errorCode: "",
          });
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              message: error?.response?.data?.message,
              errorCode: "",
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              message: "Error Encounter. Please Try Again!!",
              errorCode: "",
            })
          );
        }
      });
  };
};

export const GetOngoinTestSuccessAction = (payload: any) => {
  return {
    type: TestsActionTypes.Get_Test_List_Success_Action,
    payload: payload,
  };
};

export const InitializingTemplateLoad = (payload: any) => {
  // console.log('call',payload);

  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Template initializing...",
      })
    );
    return Promise.all([
      GeneratingTemplates(payload),
      DoaminTempalteTitle(payload.instrument_id),
    ])
      .then((response) => {
        if (response[0].status == 200 && response[1].status == 200) {
          dispatch(
            InitializingTemplateLoadSuccessAction({
              template: response[0].data,
              domain: response[1].data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          ApiCallErrorAction({
            message: "Please Login again to continue.",
            errorCode: "",
          });
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              message: error?.response?.data?.message,
              errorCode: "",
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              message: "Error Encounter. Please Try Again!!",
              errorCode: "",
            })
          );
        }
      });
  };
};

export const InitializingTemplateLoadSuccessAction = (payload: any) => {
  return {
    type: TestsActionTypes.Details_Test_Result_Success_Action,
    payload: payload,
  };
};

export const SaveTestAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Saving Testing Details...",
      })
    );
    return SaveTestData(payload.data)
      .then(async (response) => {
        if (response.status == 200) {
          await payload.enqueueSnackbar("Successfully Saved!", {
            variant: "success",
          });
          dispatch(SaveTestSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          ApiCallErrorAction({
            message: "Please Login again to continue.",
            errorCode: "",
          });
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              message: error?.response?.data?.message,
              errorCode: "",
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              message: "Error Encounter. Please Try Again!!",
              errorCode: "",
            })
          );
        }
      });
  };
};

export const SaveTestSuccessAction = (data: any) => {
  return {
    type: TestsActionTypes.Save_Test_Success_Action,
    payload: data,
  };
};

export const ValidationAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Validating Testing Details...",
      })
    );
    return ValidateTest(payload)
      .then(async (response) => {
        if (response.status == 200) {
          dispatch(ValidationSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          ApiCallErrorAction({
            message: "Please Login again to continue.",
            errorCode: "",
          });
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              message: error?.response?.data?.message,
              errorCode: "",
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              message: "Error Encounter. Please Try Again!!",
              errorCode: "",
            })
          );
        }
      });
  };
};

export const ValidationSuccessAction = (data: any) => {
  return {
    type: TestsActionTypes.Validation_Test_Success_Action,
    payload: data,
  };
};

export const GetDashboardDetail = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Dashboard initializing...",
      })
    );
    return DashboardDetailGet(payload)
      .then((response) => {
        if (response.status == 200) {
          dispatch(GetDashboardDetailSuccessAction(response.data));
          console.log("dashboard", response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          ApiCallErrorAction({
            message: "Please Login again to continue.",
            errorCode: "warn",
          });
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              message: error?.response?.data?.message,
              errorCode: "error",
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              message: "Error Encounter. Please Try Again!!",
              errorCode: "error",
            })
          );
        }
      });
  };
};

export const GetDashboardDetailSuccessAction = (payload: any) => {
  return {
    type: TestsActionTypes.Dashboard_Detail_Get_Success_Action,
    payload: payload,
  };
};
