import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../Models/reduxModels";
import { userDetails } from "../../../Models/userModels";
import { UserPasswordRestAction } from "../../../Stores/actions/userActions";
import ProfileMainView from "./ProfileMainView";

const ProfileMain = ({userDetail,UserPasswordRestAction}: Profileinterface) => {
  const { enqueueSnackbar } = useSnackbar();
  const submirAction=(props: any)=>{
    if(props.new_password==props.confirm_password){
    props.userid = userDetail?.userid
   let payload={
    data: props,
    enqueueSnackbar:enqueueSnackbar
   }
   UserPasswordRestAction(payload)
  }
  else{
    enqueueSnackbar("Password not matched!", {
      variant: "warning",
    });
  }
  }
  return <ProfileMainView userDetail={userDetail} submirAction={submirAction}/>
};


const mapStateToProps = (state: StoreState) => {
  return {
    userDetail: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  UserPasswordRestAction,
};
export default connect(mapStateToProps,mapDispatchToProps)(ProfileMain);
interface Profileinterface {
  userDetail?: userDetails;
  UserPasswordRestAction?: any;
}