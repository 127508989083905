import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { deepOrange } from "@mui/material/colors";
import { userDetails } from "../../../Models/userModels";
import { Controller, useForm } from "react-hook-form";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const ProfileMainView = ({ userDetail, submirAction }: ProfileViewProps) => {
  const [pass, Setpass] = useState<boolean>(false);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();

  const Submit = (props: any) => {
    submirAction(props);
  };
  return (
    <Card
      sx={{
        minWidth: 375,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        margin: 2,
        boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      }}
    >
      <Box
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#035779",
          padding: 5,
        }}
      >
        <Typography
          style={{ textAlign: "center", color: "#fff", fontWeight: "700" }}
        >
          Profile
        </Typography>
      </Box>
      <Grid container direction={"row"} spacing={2} sx={{ margin: 2 }}>
        <Grid item xs={2}>
          <Avatar
            sx={{
              bgcolor: deepOrange[500],
              width: 100,
              height: 100,
              padding: "20px",
            }}
          >
            <h2>{userDetail?.fullName?.charAt(0)}</h2>
          </Avatar>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction={"row"}>
            <Grid item xs={6} marginBottom={2}>
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: "bold" }}>Name</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>{userDetail?.fullName}</Typography>{" "}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} marginBottom={2}>
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: "bold" }}>User ID</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>{userDetail?.userName}</Typography>{" "}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} marginBottom={2}>
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: "bold" }}>User Type</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>{userDetail?.userType}</Typography>{" "}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} marginBottom={2}>
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: "bold" }}>Email</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>{userDetail?.email}</Typography>{" "}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} marginBottom={2}>
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: "bold" }}>Phone</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>{userDetail?.contact}</Typography>{" "}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} marginBottom={2}>
              <Grid container direction={"row"}>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Change Password
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <IconButton color="primary" onClick={() => Setpass(true)}>
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {pass && (
        <>
          <Box
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#035779",
              padding: 5,
            }}
          >
            <Typography
              style={{ textAlign: "center", color: "#fff", fontWeight: "700" }}
            >
              Change Password
            </Typography>
          </Box>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={9} sx={{ marginTop: 2, marginLeft: 15 }}>
              <Grid container direction={"column"} sx={{ margin: 2 }}>
                <Grid item xs={6} marginBottom={2} marginTop={2}>
                  <Grid container direction={"row"} sx={{ marginLeft: 2 }}>
                    <Grid item xs={5}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Old Password<span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Controller
                        control={control}
                        name={"old_password"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({
                          field: { onBlur, value, onChange, ref },
                        }) => (
                          <TextField
                            ref={ref}
                            onChange={onChange}
                            placeholder="Old Password"
                            value={value}
                            onBlur={onBlur}
                            fullWidth
                            id="basic"
                            type="Password"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} marginBottom={2} marginTop={2}>
                  <Grid container direction={"row"} sx={{ marginLeft: 2 }}>
                    <Grid item xs={5}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        New Password<span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Controller
                        control={control}
                        name={"new_password"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({
                          field: { onBlur, value, onChange, ref },
                        }) => (
                          <TextField
                            ref={ref}
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            placeholder="New Password"
                            fullWidth
                            id="basic"
                            type="Password"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} marginBottom={2} marginTop={2}>
                  <Grid container direction={"row"} sx={{ marginLeft: 2 }}>
                    <Grid item xs={5}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Confirm Password<span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Controller
                        control={control}
                        name={"confirm_password"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({
                          field: { onBlur, value, onChange, ref },
                        }) => (
                          <TextField
                            ref={ref}
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            placeholder="Confirm Password"
                            fullWidth
                            id="basic"
                            // type="Password"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} marginBottom={2} marginTop={2}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => Setpass(false)}
                        style={{
                          marginRight: 4,
                          backgroundColor: "grey",
                          color: "white",
                        }}
                      >
                        BACK
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleSubmit(Submit)}
                        style={{ color: "white" }}
                      >
                        UPDATE PASSWORD
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
};

export default ProfileMainView;

interface ProfileViewProps {
  userDetail?: userDetails;
  submirAction?: any;
}
