import {
  AccordionSummary,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { Controller, useForm } from "react-hook-form";

function Addlfield({
  template,
  meta_name,
  ChangeTemplate,
  aditional_fields,
  getTitle,
}: AddlfieldProps) {
  return (
    <Paper style={{ backgroundColor: "transparent" }}>
      {!!aditional_fields && aditional_fields.length > 1 && (
        <Grid item>
          <Box
            style={{
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "#78938a",
              padding: 8,
              width: 600,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                color: "#fff",
                fontWeight: "700",
              }}
            >
              Additional Field
            </Typography>
          </Box>
          <Grid container style={{ width: "100%" }}>
            <Grid
              container
              direction={"row"}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              {!!aditional_fields &&
                aditional_fields.map(
                  (m: any, index: number) =>
                    m.type != "IMAGE" && (
                      <Grid
                        key={index}
                        item
                        xs={4}
                        style={{
                          marginTop: 5,
                          marginBottom: 3,
                          paddingLeft: 5,
                        }}
                      >
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                        >
                          <Grid item xs={4}>
                            <Typography style={{ fontWeight: 550 }}>
                              {getTitle(m.name)}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography>:</Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography>
                              {m.type == "DATE" && !!template[m.name]
                                ? moment(template[m.name]).format(
                                    "DD/MM/YYYY, hh:mm:ss a"
                                  )
                                : !!template[m.name]
                                ? template[m.name]
                                : "N/A"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default Addlfield;

interface AddlfieldProps {
  template?: any;
  meta_name?: string;
  ChangeTemplate?: any;
  aditional_fields: any[];
  getTitle?: any;
}
