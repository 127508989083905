import {
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  styled,
  TableCell,
  tableCellClasses,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { UserList } from "../../../Models/userModels";
import { TemplateList } from "../../../Models/testModels";
const ResetDeviceView = ({ userList, ResetUser }: ResetDeviceViewProps) => {
  const columns: any[] = [
    "No.",
    "User ID",
    "User Name",
    "Contact",
    "Email",
    "Device ID",
    "Action",
  ];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<UserList[]>([]);
  const [searched, setSearched] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [ro, Setro] = useState<UserList | undefined>(undefined);
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!userList
      ? userList.filter((row: UserList) => {
          return (
            row?.fullName?.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row?.contact?.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row?.email?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const styles = {
    marginRight: "50px",
  };
  useEffect(() => {
    if (!!userList) {
      cancelSearch();
    }
  }, [userList]);
  const Reset = (r: UserList) => {
    Setro(r);
    setOpen(true);
  };
  return (
    <Paper
      sx={{
        overflow: "hidden",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        margin: 2,
        boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      }}
    >
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Reset"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset this device?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            No
          </Button>
          <Button onClick={() => (ResetUser(ro), setOpen(false))} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#035779",
          padding: 5,
        }}
      >
        <Typography style={{ textAlign: "center", color: "#fff" }}>
          Reset Devices
        </Typography>
      </Box>
      <Grid container justifyContent={"center"} alignItems="center" margin={1}>
        <Grid item xs={8}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer sx={{ maxHeight: 480 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          //   style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell align="justify" key={column}>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!rows &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row?.userName}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        {row?.fullName}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {row?.contact}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        {row?.email}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        {row?.device}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[6]}>
                        <Button
                          onClick={() => Reset(row)}
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          Reset Device
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={userList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ResetDeviceView;
interface ResetDeviceViewProps {
  userList: UserList[];
  ResetUser?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#04AFEF",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: { md: 17, sm: 12, xs: 8 },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
