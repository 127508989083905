import { Grid, Typography } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <Grid container>
      <Grid item>
        <Typography
          style={{
            fontSize: 13,
            fontWeight: 600,
            color: "#606065",
          }}
        >
          Developed By <a href="https://www.msqube.com/">MSQUBE</a>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
