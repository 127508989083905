import {
  Avatar,
  Box,
  Button,
  Checkbox,
  createTheme,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import Background from "../../../assets/background.jpg";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
const LoginMainView = ({ Submit }: LoginMainViewProps) => {
  const theme = createTheme();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    Submit({
      userid: data.get("userid"),
      password: data.get("password"),
    });
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        style={{
          backgroundImage: `url(${Background})`,
          width: "100%",
          minHeight: "100%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          item
          style={{
            background: "rgba(255, 255, 255, 0.7)",
            width: 500,
            minHeight: 250,
            borderRadius: 10,
            boxShadow: "10px 11px 40px -15px rgba(0,0,0,0.75)",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                id="userid"
                label="User ID"
                name="userid"
                autoComplete="userid"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginMainView;

interface LoginMainViewProps {
  Submit?: any;
}
