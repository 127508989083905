import {
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import FilterListIcon from "@mui/icons-material/FilterList";
import ScrollContainer from "react-indiana-drag-scroll";
function SubTestSection({
  getTitle,
  item,
  meta,
  template,
}: SubTestSectionProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const { errors } = formState;
  const GetUnit = (str: string, index: number) => {
    return template[index].find((m: any) => m.name == str).value;
  };
  return (
    <Grid>
      <Box
        style={{
          borderBottomRightRadius: 10,
          borderTopRightRadius: 10,
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "#78938a",
          padding: 8,
          width: 600,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            color: "#fff",
            fontWeight: "700",
          }}
        >
          {getTitle(meta.name)}
        </Typography>
      </Box>
      <Grid container style={{ width: "100%", marginBottom: 20 }}>
        <ScrollContainer hideScrollbars={false}>
          <div style={{ display: "flex", cursor: "grab" }}>
            {template.map((items: any, index: number) => (
              <Grid key={index} style={{ marginBottom: 3, float: "left" }}>
                {items.map(
                  (m: any, indexs: number) =>
                    m.name != "row_no" && (
                      <Grid
                        item
                        style={
                          {
                            // margin: 2
                          }
                        }
                        key={indexs}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            borderWidth: 0.5,
                            borderStyle: "solid",
                            width: "100%",
                            borderColor: "#A8A8A8",
                            minWidth: 200,
                          }}
                        >
                          {(m.type.toUpperCase() == "NUMBER" ||
                            m.type.toUpperCase() == "STRING") && (
                            <Grid
                              item
                              // xs={10}
                              style={{
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                style={{
                                  height: 20,
                                  width: "100%",
                                  backgroundColor: "rgb(175, 219, 245)",
                                }}
                              >
                                <Typography
                                  style={{
                                    // marginTop: -8,

                                    fontWeight: "600",
                                    fontSize: 14,
                                    paddingLeft: 8,
                                  }}
                                >
                                  {getTitle(m.name)}
                                </Typography>
                              </Grid>
                              <Grid style={{ height: 50, width: "100%" }}>
                                <Typography
                                  style={{
                                    // marginTop: 15,
                                    // marginBottom: 15,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                  }}
                                >
                                  {m.value}{" "}
                                  {!!m?.ext && !!m.value
                                    ? GetUnit(m?.ext, index)
                                    : ""}
                                  {!m.value && "N/A"}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {m.type.toUpperCase() == "OPTIONS" && !!m.options && (
                            <Grid
                              item
                              // xs={10}
                              style={{
                                // marginRight: 5
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                style={{
                                  height: 20,
                                  backgroundColor: "rgb(175, 219, 245)",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  style={{
                                    // marginTop: -8,

                                    fontWeight: "600",
                                    fontSize: 14,
                                    paddingLeft: 8,
                                  }}
                                >
                                  {getTitle(m.name)}
                                </Typography>
                              </Grid>
                              <Grid
                                style={{
                                  height: 50,
                                  width: 200,
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  style={{
                                    // marginTop: 15,
                                    // marginBottom: 15,
                                    paddingLeft: 8,
                                  }}
                                >
                                  {m.value}
                                  {!!m?.ext && !!m.value
                                    ? GetUnit(m?.ext, index)
                                    : ""}
                                  {!m.value && "N/A"}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
            ))}
          </div>
        </ScrollContainer>
      </Grid>
    </Grid>
  );
}

export default SubTestSection;

interface SubTestSectionProps {
  item?: any;
  getTitle?: any;
  meta?: any;
  template?: any;
}
