import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { StoreState } from "../../../Models/reduxModels";
import {
  CTDiagrameDetails,
  DomainData,
  TemplateDetail,
  TemplateList,
  ValidationRes,
} from "../../../Models/testModels";
import { userDetails, UserList } from "../../../Models/userModels";
import { ValidateTest } from "../../../Services/testService";
import {
  InitializingTemplateLoad,
  SaveTestAction,
  ValidationAction,
} from "../../../Stores/actions/testsActions";
import DetailsTestView from "./DetailsTestView";

function DetailsTest({
  InitializingTemplateLoad,
  template,
  domain_template,
  SaveTestAction,
  ValidationAction,
  validation,
  user,
}: DetailsTestProps) {
  let prams: any = useLocation();
  const [ctList, setCtList] = useState<CTDiagrameDetails[]>([]);
  const [templateType, SettemplateType] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    InitializingTemplateLoad({
      instrument_id: prams.state.instrument_id,
      job_id: prams.state.job_id,
      sl_no: prams.state.sl_no,
    });
  }, [prams.state]);

  useEffect(() => {
    if (!!template && !!template.instrument_id) {
      SettemplateType(template.instrument_id);
    }
    if (
      !!template &&
      !!template?.test_template?.test_status &&
      template?.test_template?.test_status > 1 &&
      (template?.instrument_id == "TRAF001" ||
        template?.instrument_id == "TRAF006" ||
        template?.instrument_id == "TRAF007")
    ) {
      ValidateTests();
    }
  }, [template]);

  useEffect(() => {
    if (!!template?.ct_digram) {
      setCtList(JSON.parse(JSON.stringify(template.ct_digram)));
    }
  }, [template]);

  const getTitle = (key: string): string | undefined => {
    return !!domain_template.find((m) => m.key == key)?.value
      ? domain_template.find((m) => m.key == key)?.value
      : key;
  };
  const ValidateTests = () => {
    ValidationAction({
      job_id: template?.job_id,
      instrument_id: template?.instrument_id,
      sl_no: template?.sl_no,
    });
  };
  const StatusChange = (status: number) => {
    if (!!user) {
      let payload = JSON.parse(JSON.stringify(template));
      payload.test_template.checked_approved_by = user.userid;
      payload.test_template.test_status = status;
      SaveTestAction({
        data: payload,
        enqueueSnackbar: enqueueSnackbar,
      });
    }
  };
  const [open, setOpen] = useState<boolean>(false);
  const [ro, Setro] = useState<any>();

  return (
    <Paper
      sx={{
        overflow: "hidden",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        margin: 2,
        boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      }}
    >
      <DetailsTestView
        ctList={ctList}
        instrument_type={
          templateType == "TRAF001"
            ? 1
            : templateType == "TRAF002"
            ? 2
            : templateType == "TRAF003"
            ? 3
            : templateType == "TRAF004"
            ? 4
            : templateType == "TRAF005"
            ? 6
            : templateType == "TRAF006"
            ? 5
            : templateType == "TRAF007"
            ? 5
            : 0
        }
        template={template}
        test={template?.test_template}
        getTitle={getTitle}
        ValidateTest={ValidateTests}
        StatusChange={(data: number)=> (Setro(data),setOpen(true))}
        changeData={(attr: any) =>
          SaveTestAction({
            data: {
              ...template,
              test_template: attr,
            },
            enqueueSnackbar: enqueueSnackbar,
          })
        }
        validation={validation}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">{"Reset"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            Are you sure you want to do this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            No
          </Button>
          <Button onClick={() => (StatusChange(ro),setOpen(false))} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
const mapStateToProps = (state: StoreState) => {
  return {
    template: state.tests.template,
    user: state.user.userDetails,
    domain_template: state.tests.domain_template,
    validation: state.tests.validation_res,
  };
};
const mapDispatchToProps = {
  InitializingTemplateLoad,
  SaveTestAction,
  ValidationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsTest);
interface DetailsTestProps {
  InitializingTemplateLoad?: any;
  template?: TemplateDetail;
  domain_template: DomainData[];
  SaveTestAction?: any;
  ValidationAction?: any;
  validation?: ValidationRes;
  user?: userDetails;
}
