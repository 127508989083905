import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ResetDevices from "./ResetDevice";

const UserManagement = () => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}/reset-devices`} />}
      />
      <Route
        exact
        path={`${path}/reset-devices`}
        render={() => <ResetDevices />}
      />
    </Switch>
  );
};

export default UserManagement;
