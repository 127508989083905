import { StoreState } from "../../Models/reduxModels";

const InitialState: StoreState = {
  loading: {
    count: 0,
    message: "",
  },
  error: {
    error: undefined,
  },
  user: {
    userDetails: undefined,
    userList: [],
  },
  tests: {
    test_list: [],
    template: undefined,
    domain_template: [],
    validation_res: undefined,
    dashboard_detail: undefined,
    template_list: []
  },
};

export default InitialState;
