import {
  Paper,
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DonutChart from "react-donut-chart";
import {
  DashboardDetail,
  SavedTest,
  TemplateList,
} from "../../../Models/testModels";
import "./DashboardStyle.css";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  PieChart,
  Pie,
  LabelList,
  Cell,
  Legend,
} from "recharts";
import { Padding } from "@mui/icons-material";
function DashBoardMainView({
  dashboarddetail,
  OpenOngoingTest,
  OpenSubmittedTest,
  OpenOngoingTestList,
  OpenSubmittedTestList,
}: DashBoardMainViewProps) {
  useEffect(() => {
    if (!!dashboarddetail) {
      setPieData([
        {
          name: "Submitted",
          value: dashboarddetail?.submittedcount,
        },
        {
          name: "Completed",
          value: dashboarddetail?.completedcount,
        },
        {
          name: "Ongoing",
          value: dashboarddetail?.savedCount,
        },
        {
          name: "Pass Test",
          value: dashboarddetail.passedcount,
        },
        {
          name: "Fail Test",
          value: dashboarddetail.failedcount,
        },
      ]);
    }
  }, [dashboarddetail]);
  const [pieData, setPieData] = useState<any[]>([
    {
      name: "Submitted",
      value: 0,
    },
    {
      name: "Completed",
      value: 0,
    },
    {
      name: "Ongoing",
      value: 0,
    },
    {
      name: "Pass Test",
      value: 0,
    },
    {
      name: "Fail Test",
      value: 0,
    },
  ]);
  const [rows, setRows] = useState<TemplateList[]>([]);
  const column1: any[] = ["Sl No", "Location", "Test Object", "Job Start At"];
  const column2: any[] = ["Sl No", "Location", "Test Object", "Job Ends At"];
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#708238",
    "#FF8042",
    "#ff8a65",
    "#689f38",
    "#f06292",
    "#303f9f",
    "#afb42b",
  ];
  let renderLabel = function (entry: any) {
    return entry.name + " (" + entry.value + ")";
  };
  return (
    <Paper
      sx={{
        overflow: "hidden",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        margin: 2,
        boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;",
        // height: "100%",
      }}
    >
      <Box
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#035779",
          padding: 5,
        }}
      >
        <Typography
          style={{ textAlign: "center", color: "#fff", fontWeight: "700" }}
        >
          Dashboard
        </Typography>
      </Box>
      <Grid container width={"100%"}>
        <Paper
          elevation={4}
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 1,
            bgcolor: "#fff",
            boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;",
            // height: 350,
            width: "100%",
            margin: 2,
          }}
        >
          <Grid
            container
            // xs={12}
            direction={"row"}
            // style={{ flexWrap: "nowrap" }}
          >
            <Grid item xs={6} style={{ padding: 20 }}>
              <ResponsiveContainer width="100%" aspect={2}>
                <PieChart>
                  <Tooltip />
                  <Pie
                    data={pieData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={110}
                    fill="#8884d8"
                    label={renderLabel}
                    xlinkShow="true"
                    legendType="square"
                    paddingAngle={1}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xs={6} style={{ padding: 20 }}>
              <ResponsiveContainer width="100%" aspect={2}>
                <BarChart
                  data={
                    !!dashboarddetail && !!dashboarddetail.countbytype
                      ? dashboarddetail.countbytype
                      : []
                  }
                  margin={{ top: 10, right: 30, left: 0, bottom: 15 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis allowDataOverflow={true} dataKey="_id" fontSize={10} />
                  <YAxis dataKey="count" />
                  <Tooltip />
                  <Legend attributeName="Test Object" />
                  <Bar
                    dataKey="count"
                    fill="#035779"
                    name="Completed Test Object"
                  >
                    {dashboarddetail?.countbytype.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % 20]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container direction={"row"}>
        <Grid item xs={6} padding={2}>
          <Card style={{ padding: 3, height: "100%" }} elevation={4}>
            {/* <CardActionArea> */}
            <CardContent style={{ padding: 0 }}>
              <Grid container direction={"column"}>
                <Grid
                  container
                  width={"100%"}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{ textAlign: "center" }}
                    paddingLeft={2}
                    fontWeight={"600"}
                  >
                    Ongoing Test
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => OpenOngoingTestList()}
                    size="small"
                    style={{
                      paddingRight: 5,
                      textDecoration: "underline",
                      fontWeight: "600",
                    }}
                  >
                    Show More
                  </Button>
                </Grid>
                <Grid container direction={"row"} width={"100%"}>
                  <TableContainer sx={{ width: "100%" }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      sx={{
                        "& .MuiTableRow-root:hover": {
                          backgroundColor: "#05F0B9",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {column1.map((column) => (
                            <StyledTableCellOngoing
                              align="justify"
                              key={column}
                            >
                              {column}
                            </StyledTableCellOngoing>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!dashboarddetail &&
                          !!dashboarddetail.savedTests &&
                          dashboarddetail.savedTests.map((item, index) => {
                            return (
                              <StyledTableRowOngoing
                                onClick={() => OpenSubmittedTest(item)}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item?._id}
                              >
                                <StyledTableCellOngoing
                                  align="left"
                                  key={column1[0]}
                                >
                                  {item.sl_no}
                                </StyledTableCellOngoing>
                                <StyledTableCellOngoing
                                  align="left"
                                  key={column1[1]}
                                >
                                  {item.location}
                                </StyledTableCellOngoing>
                                <StyledTableCellOngoing
                                  align="left"
                                  key={column1[2]}
                                >
                                  {item.instrument_name}
                                </StyledTableCellOngoing>
                                <StyledTableCellOngoing
                                  align="left"
                                  key={column1[3]}
                                >
                                  {item.job_start_at}
                                </StyledTableCellOngoing>
                              </StyledTableRowOngoing>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} padding={2}>
          <Card style={{ padding: 3, height: "100%" }} elevation={4}>
            <CardContent style={{ padding: 0 }}>
              <Grid container direction={"column"}>
                <Grid
                  container
                  width={"100%"}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    paddingLeft={2}
                    fontWeight={"600"}
                  >
                    Submitted Test
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => OpenSubmittedTestList()}
                    size="small"
                    style={{
                      paddingRight: 5,
                      textDecoration: "underline",
                      fontWeight: "600",
                    }}
                  >
                    Show More
                  </Button>
                </Grid>
                <Grid container direction={"row"} width={"100%"}>
                  <TableContainer sx={{ width: "100%" }}>
                    <Table stickyHeader aria-label="sticky table" sx={{
                        "& .MuiTableRow-root:hover": {
                          backgroundColor: "#05F0B9",
                          cursor: "pointer",
                        },
                      }}>
                      <TableHead>
                        <TableRow>
                          {column2.map((column) => (
                            <StyledTableCellSubmitted
                              align="justify"
                              key={column}
                            >
                              {column}
                            </StyledTableCellSubmitted>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!dashboarddetail &&
                          !!dashboarddetail.submittedTests &&
                          dashboarddetail.submittedTests.map((item, index) => (
                            <StyledTableRowSubmitted
                              onClick={() => OpenSubmittedTest(item)}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={item._id}
                              className={"dashboard"}
                            >
                              <StyledTableCellSubmitted
                                align="left"
                                key={column2[0]}
                              >
                                {item.sl_no}
                              </StyledTableCellSubmitted>
                              <StyledTableCellSubmitted
                                align="left"
                                key={column2[1]}
                              >
                                {item.location}
                              </StyledTableCellSubmitted>
                              <StyledTableCellSubmitted
                                align="left"
                                key={column2[2]}
                              >
                                {item.instrument_name}
                              </StyledTableCellSubmitted>
                              <StyledTableCellSubmitted
                                align="left"
                                key={column2[3]}
                              >
                                {item.job_end_at}
                              </StyledTableCellSubmitted>
                            </StyledTableRowSubmitted>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DashBoardMainView;

interface DashBoardMainViewProps {
  dashboarddetail?: DashboardDetail;
  OpenOngoingTest?: any;
  OpenOngoingTestList?: any;
  OpenSubmittedTest?: any;
  OpenSubmittedTestList?: any;
}

const StyledTableCellOngoing: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#04AFEF",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: { md: 17, sm: 12, xs: 8 },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRowOngoing = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: "#05F0B9",
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "#05F0B9",
  },
}));

const StyledTableCellSubmitted: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#708238",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: { md: 17, sm: 12, xs: 8 },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRowSubmitted = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: "#05F0B9",
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "#05F0B9",
  },
}));
